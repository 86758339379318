<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
  	window.onload = function() {
  		var oHtml = document.getElementsByTagName("html")[0];
  		var screenW = document.documentElement.offsetWidth || document.body.offsetWidth;
  		oHtml.style.fontSize = 100 * screenW / 750 + "px";
  	}
  	window.onresize = function() {
  		var oHtml = document.getElementsByTagName("html")[0];
  		var screenW = document.documentElement.offsetWidth || document.body.offsetWidth;
  		oHtml.style.fontSize = 100 * screenW / 750 + "px";
  	}
  </script>
<style lang="scss">
#app {
  // font-family: Avenir,sans-serif, PingFangSC-Medium;
  // font-family: 'Noto Serif CJK SC', 'Noto Serif CJK', 'Source Han Serif SC', ‘Source Han Serif’, source-han-serif-sc, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
  font-size: 0.3rem;
}
html,body{
	height: 100%;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
